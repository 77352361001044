<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <app-form
    ref="form"
    :errors="errors"
  >
    <auth-card>
      <auth-logo />
      <v-card-title>{{ $t('login') }}</v-card-title>
      <v-card-subtitle>{{ $t('loginPage.loginSubtitle') }}</v-card-subtitle>
      <v-card-text>
        <v-container
          class="py-0"
          fluid
        >
          <v-row no-gutters>
            <app-text-input
              v-model="form.login_id"
              :hint="parseAttribute('username')"
              cols="12"
              dense
              label="username"
              name="login_id"
            />
            <app-password-input
              v-model="form.password"
              cols="12"
              hide-icon
              name="password"
            />
          </v-row>
          <!--          <v-row no-gutters>
            <v-col cols="auto">
              <router-link
                :to="route(APP_ROUTES.auth.forgotPassword)"
                class="text-decoration-underline font-weight-bold"
              >
                {{ $t('loginPage.forgetPassword') }}
              </router-link>
            </v-col>
          </v-row>-->
          <v-row>
            <v-col>
              <app-btn
                :block="AppIsSmall"
                :disabled="disableLogin"
                :loading="loading"
                @click="submit"
              >
                {{ $t('login') }}
              </app-btn>
            </v-col>
          </v-row>
          <!--          <v-row>
            <v-col cols="12">
              <p class="ma-0">
                <span>{{ $t('loginPage.no_account') }}</span>
                <router-link
                  :to="route(APP_ROUTES.auth.register)"
                  class="text-decoration-underline font-weight-bold"
                >
                  {{ $t('loginPage.create_account') }}
                </router-link>
              </p>
            </v-col>
          </v-row>-->
        </v-container>
      </v-card-text>
    </auth-card>
  </app-form>
</template>

<script>

import AuthCard from '@components/auth/AuthCard'
import AuthLogo from '@components/auth/AuthLogo'

export default {
  name: 'Login',
  components: { AuthLogo, AuthCard },
  data () {
    return {
      text: '',
      loading: !1,
      errors: {},
      form: {
        login_id: '',
        password: ''
      }
    }
  },
  computed: {
    disableLogin () {
      return !(this.form.login_id && this.form.password)
    }
  },
  mounted () {
    if (this.authUser) {
      // this.navigate(this.APP_ROUTES.homePage)
    }
    // console.log(this.$vuetify.breakpoint)
  },
  methods: {
    async submit () {
      if (this.loading || this.disableLogin) return
      this.loading = !0

      const form = { ...this.form, push_token: window.push_token || null }

      if (this.IsMobileApp) {
        form.is_mobile = !0
      }

      this.errors = {}

      try {
        const { data, _data: user, _success } = await this.apiService.auth.login(form)
        const token = data?.token
        if (token && _success === !0 && user) {
          this.loginUser({ token, user })
        } else {
          // this.loading = !1
        }
      } catch (e) {
        // alert(e)
        const { _message, _errors } = e
        this.errors = _errors || {}
        this.form.password = ''
        const message = _message || e.message
        // message && this.alertError(JSON.stringify(Object.keys(e.request)))
        // this.alertError(JSON.stringify(e))
        message && this.alertError(message)
      } finally {
        this.loading = !1
      }
    }
  }
}
</script>
